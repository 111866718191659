.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.list-item {
  margin: 30px 0;
}

.pagination-wrapper {
  flex-basis: 100%;
  margin: 30px 0;
}

.pagination-item {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}


@media screen and (max-width: 900px) {
  .twitter-tweet {
    max-width: 450px!important;
  }
}

@media screen and (max-width: 600px) {
  .twitter-tweet {
    max-width: 400px!important;
  }
}

@media screen and (max-width: 450px) {
  .twitter-tweet {
    max-width: 350px!important;
  }
}
