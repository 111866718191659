html, body {
  margin: 0;
  padding: 0;
  width: 100vw;
  text-align: center;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.list-item {
  margin: 30px 0;
}

.pagination-wrapper {
  flex-basis: 100%;
  margin: 30px 0;
}

.pagination-item {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}


@media screen and (max-width: 900px) {
  .twitter-tweet {
    max-width: 450px!important;
  }
}

@media screen and (max-width: 600px) {
  .twitter-tweet {
    max-width: 400px!important;
  }
}

@media screen and (max-width: 450px) {
  .twitter-tweet {
    max-width: 350px!important;
  }
}


.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.list-wrapper {
  max-width: 600px;
  text-align: left;
}

.list-item {
  margin: 30px 0;
}

.child {
  margin-top: 10px;
  padding-left: 15px;
}

.pagination-wrapper {
  flex-basis: 100%;
  margin: 30px 0;
}

.pagination-item {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .list-wrapper {
    max-width: 450px;
  }
}

@media screen and (max-width: 600px) {
  .list-wrapper {
    max-width: 400px;
  }
}

@media screen and (max-width: 450px) {
  .list-wrapper {
    max-width: 350px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin: 20px 0 0;
}

