
.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.list-wrapper {
  max-width: 600px;
  text-align: left;
}

.list-item {
  margin: 30px 0;
}

.child {
  margin-top: 10px;
  padding-left: 15px;
}

.pagination-wrapper {
  flex-basis: 100%;
  margin: 30px 0;
}

.pagination-item {
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .list-wrapper {
    max-width: 450px;
  }
}

@media screen and (max-width: 600px) {
  .list-wrapper {
    max-width: 400px;
  }
}

@media screen and (max-width: 450px) {
  .list-wrapper {
    max-width: 350px;
  }
}
